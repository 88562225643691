<template>
  <div class="row">
    <div class="col-12">
      <Toast ref="mensajeToast"></Toast>
      <Alerts ref="alert"></Alerts>
      <ModalPDF ref="visorPDF"></ModalPDF>
      <Confirm ref="confirm"></Confirm>
      <ModalVideo ref="modalVideo"></ModalVideo>
      <transition name="slide">
        <div class="card border-warning">
          <div class="card-header">
            <strong>Pagos por estudiante</strong>
            <div class=" card-header-actions">
              <form class="form-inline">
                <input id="user" type="hidden" value="<?php echo $user->id ?>"/>
                <b>Filas por p&aacute;gina</b>
                <select class="form-control mr-2 ml-1" @change="sizeChange($event)"
                        v-model="filasPorPagina">
                  <option v-for="option in sizeoptions" :value="option.value">
                    {{ option.text }}
                  </option>
                </select>

                <button class="btn btn-outline-dark mr-2" type="button"
                        @click="borrarFiltroBtnOnClick()">
                  <font-awesome-icon icon="fa-sort-amount-up-alt"/>
                  Quitar Filtro
                </button>

                <button class="btn btn-outline-primary ml-2" data-html="true"
                        data-toggle="tooltip" title="Inscribir un <b>nuevo</b> estudiante" type="button"
                        @click="nuevoBtnOnClick()">
                  <font-awesome-icon icon="fa-plus"/>
                  Nuevo
                </button>

                <button class="btn btn-outline-success ml-2" data-html="true"
                        data-toggle="tooltip"
                        title="Para exportar todos los datos en un archivo Excel<br/>seleccione la opci&oacute;n <u>Todos</u> en <b>Filas por p&aacute;gina</b>"
                        type="button"
                        @click="excelBtnOnClick()">
                  <font-awesome-icon icon="fa-file-excel"/>
                  Exportar a Excel
                </button>
              </form>
            </div>
          </div>
          <div class="card-body p-0 pr-1">
            <JqxGrid :width="'100%'" ref="gridSystem"
                     :source="dataAdapter"
                     :autoheight="true" :autorowheight="false" :rowsheight="50"
                     :pageable="true"
                     :pagesize="parseInt(filasPorPagina)"
                     :virtualmode="true"
                     :rendergridrows="rendergridrows"
                     :columns="columns" :enablebrowserselection="true"
                     :enabletooltips="true" :filterable="true"
                     :showfilterrow="true"
                     :sortable="true"
                     :pagermode="'simple'" :localization="localization"
                     :theme="'bootstrap'" :columnsresize="true"/>

            <hr class="border-primary border-1" v-if="codigo_alumno>0"/>
            <FrmDatosEstudiante v-if="codigo_alumno>0"
                                ref="frmDatosEstudiante"
                                :facultades="facultades" :carreras="carreras"
                                :nacionalidades="nacionalidades" :inscripcion="inscripcion">
            </FrmDatosEstudiante>
            <FrmPagosEstudiante v-if="codigo_alumno>0"
                                ref="frmPagosEstudiante"
                                :codigo_alumno="codigo_alumno">
            </FrmPagosEstudiante>
          </div>
        </div>
      </transition>
    </div>
    <CModal
        :centered="true"
        :closeOnBackdrop="false"
        :fade="true"
        :no-close-on-backdrop="true"
        :show.sync="darkModal"
        size="xl"
    >
      <template #body-wrapper>
        <div class="modal-body p-0 m-0">
          <embed id="reporteFrame" height="550" src="" type="application/pdf"
                 width="100%">
        </div>
      </template>
      <template #header>
        <h6 class="modal-title">
          <a id="reporteDescargarBtn" download="download" href="">Descargar el documento</a>
        </h6>
        <CButtonClose @click="darkModal = false"/>
      </template>
      <template #footer-wrapper><span></span></template>
    </CModal>
  </div>
</template>

<script>
import axios from 'axios'
import JqxGrid from "jqwidgets-scripts/jqwidgets-vue/vue_jqxgrid";
import jqxInput from "jqwidgets-scripts/jqwidgets-vue/vue_jqxinput";
import Toast from "@/views/notifications/Toast";
import Alerts from "@/views/notifications/Alerts";
import ModalPDF from "@/views/notifications/ModalPDF";
import Confirm from "@/views/notifications/Confirm";
import ModalVideo from "@/views/notifications/ModalVideo";
import FrmPagosEstudiante from "@/views/facturaenlinea/frmpagosestudiante";
import FrmDatosEstudiante from "@/views/facturaenlinea/frmdatosestudiante";

var sourceLst;
var address;
var modalPdf;
var pagesizeoptions = [3, 6, 10, 50, 100, 500, 1000, 5000, 10000];
var pagesize = pagesizeoptions[0];

export default {
  name: 'Alumnos',
  components: {
    ModalVideo,
    Confirm,
    ModalPDF,
    Alerts,
    Toast,
    JqxGrid,
    jqxInput,FrmPagosEstudiante,FrmDatosEstudiante
  },
  data: () => {
    return {
      darkModal: false,
      items: [],
      currentPage: 1,
      perPage: 7,
      totalRows: 0,
      codigo_alumno: 0,
      filasPorPagina: pagesizeoptions[0],
      inscripcion: {
        facultad_id: 0,
        carrera_id: 0,
        codigo_alumno: '',
        gestion: '',
        apellido1: '',
        apellido2: '',
        nombres: '',
        genero_id: 0,
        fecha_nacimiento: '',
        nacionalidad_id: 0,
        documento_identificacion: '',
        traspaso_id: 0,
        estado_civil_id: 0,
        trabaja_id: 0,
        direccion: '',
        telefono: '',
        email: '',
        casilla: '',
      },
      sizeoptions: [],
      sedeSeleccionada: localStorage.sedeSeleccionada,
      facultades: [], carreras: {},
      generos: [], estados_civiles: [],
      nacionalidades: [], si_nos: [],
      dataAdapter: new jqx.dataAdapter(sourceLst, {
        loadError: function (xhr, status, error) {
          console.log("Error ocurrido al obtener los datos del servidor, " + status + ", " + error);
          location.href = location.protocol + "//" + location.host + "/#/login";
        }
      }),
      rendergridrows: (params) => {
        return params.data;
      },
      columns: [
        {
          text: 'Código Alumno', datafield: "codigo_alumno", width: '8%', cellsalign: 'center',
          renderer: function () {
            return '<div style="margin-top: 5px; margin-left: 5px;">Código<br />Alumno</div>';
          },
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar Código Alumno"});
          }
        },
        {
          text: 'Apellido1', datafield: "apellido1", width: '13%',
          renderer: function () {
            return '<div style="margin-top: 5px; margin-left: 5px;">Primer<br />apellido</div>';
          },
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar Apellido1"});
          }
        },
        {
          text: 'Apellido2', datafield: "apellido2", width: '13%',
          renderer: function () {
            return '<div style="margin-top: 5px; margin-left: 5px;">Segundo<br />apellido</div>';
          },
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar Apellido2"});
          }
        },
        {
          text: 'Nombres', datafield: "nombres", width: '16%',
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar Nombres"});
          }
        },
        {
          text: 'Carrera', datafield: "carrera", width: '20%',
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar Carrera"});
          }
        },
        {
          text: "", width: '15%', columntype: "button",
          filterable: false, sortable: false,
          cellclassname: "btn-green",
          renderer: function () {
            return '';
          },
          cellsrenderer: function (index, datafield, value, defaultvalue, column, rowdata) {
            return ((rowdata.inscripcion_id > 0) ? "Pagos del estudiante" : ":: Anulado ::");
          },
          buttonclick: function (row, event) {
            let button = $(event.currentTarget);
            let grid = button.parents("[id^=jqxGrid]");
            let rowData = grid.jqxGrid('getrowdata', row);
            if (rowData.inscripcion_id > 0) {
              modalPdf.inscripcion=rowData
              modalPdf.codigo_alumno=rowData.codigo_alumno
            }
          }
        },
        {
          text: "", width: '15%', columntype: "button",
          filterable: false, sortable: false,
          cellclassname: "btn-orange",
          renderer: function () {
            return '';
          },
          cellsrenderer: function (index, datafield, value, defaultvalue, column, rowdata) {
            return ((rowdata.inscripcion_id > 0) ? "Estado de cuentas" : ":: Anulado ::");
          },
          buttonclick: function (row, event) {
            let button = $(event.currentTarget);
            let grid = button.parents("[id^=jqxGrid]");
            let rowData = grid.jqxGrid('getrowdata', row);
            if (typeof rowData.inscripcion_id !=='undefined') {
              modalPdf.$refs.alert.show("Generando Reporte");

              axios.get(modalPdf.$apiAdress + '/api/reporte/'+rowData.codigo_alumno+'/estudianteestadocuentas?token=' + localStorage.getItem("api_token")+ '&sede_id=' + localStorage.sedeSeleccionada,
                  {
                    responseType: 'blob',
                  }
              )
                  .then((response) => {
                    const url = URL.createObjectURL(new Blob([response.data], {
                      type: 'application/vnd.ms-excel'
                    }))
                    let fecha = (new Date()).toISOString().split('T')
                    const link = document.createElement('a')
                    link.href = url
                    link.setAttribute('download', rowData.codigo_alumno+'_'+fecha[0]+'_'+fecha[1]+'.xlsx')
                    document.body.appendChild(link)
                    link.click()
                    modalPdf.$refs.alert.hide();
                  })
                  .catch(function (error) {
                    modalPdf.$refs.mensajeToast.mostrarError(error,modalPdf);
                    modalPdf.$refs.alert.hide();
                  });
            }
          }
        },
        {text: '', datafield: "facultad_id", hidden: true},
        {text: '', datafield: "carrera_id", hidden: true},
        {text: '', datafield: "nacionalidad_id", hidden: true},
      ],
      localization: {
        'emptydatastring': "No existen datos para esta SEDE",
      },
    }
  },
  beforeCreate: function () {
    const url = this.$apiAdress + '/api/inscripcion?token=' + localStorage.getItem("api_token") + '&sede_id=' + localStorage.sedeSeleccionada;
    address = this.$apiAdress;
    sourceLst = {
      url: url,
      datafields: [
        {name: 'inscripcion_id', type: "int"},
        {name: 'codigo_alumno', type: "string"},
        {name: 'apellido1', type: "string"},
        {name: 'apellido2', type: "string"},
        {name: 'nombres', type: "string"},
        {name: 'carrera', type: "string"},
        {name: 'gestion', type: "string"},
        {name: 'estado', type: "string"},
        {name: 'facultad_id', type: "int"},
        {name: 'carrera_id', type: "int"},
        {name: 'nacionalidad_id', type: "int"},
      ],
      data: {
        sede: this.sede
      },
      datatype: "json",
      root: "Rows",
      id: 'codigo_alumno',
      sortcolumn: 'codigo_alumno',
      sortdirection: 'desc',
      cache: false,
      beforeprocessing: (data) => {
        if (data != null) {
          sourceLst.totalrecords = data[0].TotalRows;
          let i = 0;
          this.sizeoptions = [];
          while (i < 8 && pagesizeoptions[i] < data[0].TotalRows) {
            this.sizeoptions[i] = {text: pagesizeoptions[i], value: pagesizeoptions[i]};
            i++;
          }
          if (i > 1) {
            this.sizeoptions[i] = {text: "TODOS", value: data[0].TotalRows};
          }
        }
        modalPdf.$refs.alert.hide();
      },
      filter: () => {
        this.$refs.gridSystem.updatebounddata("filter");
      },
      sort: () => {
        this.$refs.gridSystem.updatebounddata("sort");
      },
    };
  },
  methods: {
    hasRole(roleUser) {
      let roles = localStorage.getItem("roles");
      if (roles != null) {
        roles = roles.split(',')
        if (roles != null && roles.indexOf(roleUser) >= 0) {
          return true
        }
      }
      return false;
    },
    showHideColumn(role) {
      try {
        if (!modalPdf.hasRole(role)) {
          this.$refs.gridSystem.hidecolumn(role);
        }
      } catch (e) {
      }
    },
    excelBtnOnClick: function () {
      this.$refs.gridSystem.hidecolumn('documentos');
      this.$refs.gridSystem.hidecolumn('editar');
      this.$refs.gridSystem.hidecolumn('eliminar');
      let date = new Date();
      let dateStr =
          date.getFullYear() + "" +
          ("00" + (date.getMonth() + 1)).slice(-2) + "" +
          ("00" + date.getDate()).slice(-2) + "_" +
          ("00" + date.getHours()).slice(-2) + "" +
          ("00" + date.getMinutes()).slice(-2) + "" +
          ("00" + date.getSeconds()).slice(-2);
      this.$refs.gridSystem.exportdata('xls', 'Inscritos' + dateStr, true, null, true, modalPdf.$apiAdress + "/api/reporte/exportarxls/" + '?token=' + localStorage.getItem("api_token"));
      this.$refs.gridSystem.showcolumn('documentos');
      this.$refs.gridSystem.showcolumn('editar');
      this.$refs.gridSystem.showcolumn('eliminar');
    },
    nuevoBtnOnClick: function () {
      this.$router.push({path: '/inscripcion/nuevo'});
    },
    borrarFiltroBtnOnClick: function () {
      this.$refs.gridSystem.clearfilters();
    },
    sizeChange: function (event) {
      try {
        modalPdf.$refs.alert.show("Actualizando datos");
        this.$refs.gridSystem.pagesize = parseInt(this.filasPorPagina);
        this.$refs.gridSystem.updatebounddata();
      } catch (e) {}
    }
  },
  mounted() {
    modalPdf = this;
    this.$root.$on('sedeSeleccionada', data => {
      left.sede = data;
    });
    axios.get(this.$apiAdress + '/api/reporte/' + this.sedeSeleccionada + '/parametrospagosestudiantes?token=' + localStorage.getItem("api_token"))
        .then(function (response) {
          let valorPorDefecto = [{value: '', label: ':: SELECCIONAR ::'}];
          modalPdf.facultades = valorPorDefecto.concat(response.data.facultades);
          modalPdf.carreras = response.data.carreras;
          modalPdf.generos = valorPorDefecto.concat(response.data.generos);
          modalPdf.estados_civiles = valorPorDefecto.concat(response.data.estados_civiles);
          modalPdf.nacionalidades = valorPorDefecto.concat(response.data.nacionalidades);
          modalPdf.si_nos = valorPorDefecto.concat(response.data.si_nos);
        })
        .catch(function (error) {
          modalPdf.$refs.mensajeToast.makeToast('Error', error.response.data.message, 'danger');
        });
    modalPdf.showHideColumn('documentos')
    modalPdf.showHideColumn('compromisos');
    modalPdf.showHideColumn('contratos');
    modalPdf.showHideColumn('hojaruta');
  },
}
</script>
<style scoped>
.c-icon {
  margin-right: 0.3rem;
}
</style>